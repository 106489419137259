export const UN_REGISTERED_USER_ID = -1

export const STRUCTURED_DESCRIPTION_QUESTIONS = {
  jobTitle: 'Specify a clear, specific job title that precisely describes the role. Avoid terms like "ninja," "rock star," or "guru.”',
  positionOverview: 'Provide an overview of the position, including its key objectives, reporting structure, and its importance within the team and the broader organization.',
  location: 'Indicate whether the job is remote, onsite, or hybrid.',
  onsite: 'Specify the city for the onsite job.',
  aboutCompany: 'Include a short summary detailing the company\'s work environment, team, and values to give candidates a quick snapshot of your company’s culture.',
  benefits: 'Outline the benefits like compensation, healthcare, and career growth to attract quality candidates.',
  yearsOfExperience: 'Specify the years of experience required to set clear expectations for potential applicants.',
  mustHaveTechnologies: 'Specify required qualifications, including education, experience, and skills, to set clear applicant criteria.',
  niceToHaveTechnologies: 'Add any "nice-to-have" qualifications or skills that would make a candidate stand out for this job, but are not strictly necessary for consideration.',
  salary: 'Please provide the salary range for this position.'
}