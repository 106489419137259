import axios, { AxiosInstance } from 'axios'
import { useToast } from 'vue-toastification'
const toast = useToast()

export class HttpService {
  axios: AxiosInstance;
  constructor () {
    this.axios = axios.create({
      baseURL: import.meta.env.PROD ? import.meta.env.VITE_API_URL : ''
    })
    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const errors = error.response?.data?.data?.errors;
        const errorMessage = error.response?.data?.data?.message;
        if (errors?.length) {
          let message = ''
          for (let i = 0; i < errors.length; i ++) {
            message += `${errors[i].message} - ${errors[i].property} \n`;
          }
          toast.error(message);
        } else if (errorMessage) {
          toast.error(errorMessage);
        } else {
          toast.error('Something went wrong.');
        }
        return Promise.reject(error);
      }
    );
  }
}