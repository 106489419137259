import { defineStore } from 'pinia'
import { CandidateStatus } from '@/interfaces/Job'

export const useJobStore = defineStore('job', {
  state: () => ({
    jobDescription: '',
    jobName: '',
    jobHash: '',
    analysis: null,
    orderedAt: null,
    candidatesCounts: {
      [CandidateStatus.Hidden]: 0,
      [CandidateStatus.Presented]: 0,
      [CandidateStatus.Selected]: 0,
      [CandidateStatus.Invited]: 0,
      [CandidateStatus.Rejected]: 0,
      [CandidateStatus.Contacted]: 0,
    }
  }),
  actions: {
    setJobDescription(value: string) {
      this.jobDescription = value
    },
    setAnalysis(value: any) {
      this.analysis = value
    },
    setName(value: string) {
      this.jobName = value
    },
    setHash(value: string) {
      this.jobHash = value
    },
    setOrderedAt(value: string | null) {
      this.orderedAt = value
    },
    setCandidatesCounts(value: any) {
      this.candidatesCounts = {
        ...{
          [CandidateStatus.Hidden]: 0,
          [CandidateStatus.Presented]: 0,
          [CandidateStatus.Selected]: 0,
          [CandidateStatus.Invited]: 0,
          [CandidateStatus.Rejected]: 0,
        },
        ...value
      }
    }
  },
})