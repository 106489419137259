<template>
  <v-dialog
    class="plan-promocode"
    width="728"
    :model-value="modelValue"
    @update:model-value="updateValue"
  >
    <v-card class="plan-promocode__card">
      <div>
        <p class="plan-promocode__title">
          Use a promo code
        </p>
        <div class="plan-promocode__form flex">
          <base-input
            v-model="promocode"
            class="plan-promocode__form__input"
            placeholder="Promo code"
          />
          <v-btn
            color="primary"
            class="plan-promocode__form__btn"
            :disabled="!promocode"
            @click="applyPromocode"
          >
            Apply
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue'
import { useModalsStore } from '@/store/ModalsStore'
import BaseInput from '@/components/Core/BaseInput.vue'

defineProps({
  modelValue: {
    type: Boolean,
    required: true,
    default: false
  }
})
const modalsStore = useModalsStore()
const promocode = ref('')

const applyPromocode = async () => {
  if (modalsStore.planPromocodeModal.successCallback) {
    await modalsStore.planPromocodeModal.successCallback(promocode.value)
  }
  updateValue(false)
}


const emit = defineEmits([ 'update:modelValue'])

const updateValue = (value: boolean) => {
  emit('update:modelValue', value)
}
</script>
<style lang="scss" scoped>
.plan-promocode {
  &__card {
    padding: 32px;
  }
  &__title {
    font-size: 22px;
    font-weight: 400;
    color: $BLACK;
    margin-bottom: 32px;
  }
  &__form {
    width: 100%;
    align-items: stretch;
    &__input {
      flex-grow: 1;
    }
    &__btn {
      flex-shrink: 0;
      margin-left: 12px;
      height: auto!important;
    }
  }
}
</style>