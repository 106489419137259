export enum JobAnalyze {
  JobTitle = 'jobTitle',
  PositionOverview = 'positionOverview',
  Location = 'location',
  Onsite = 'onsite',
  AboutCompany = 'aboutCompany',
  Benefits = 'benefits',
  YearsOfExperience = 'yearsOfExperience',
  MustHaveTechnologies = 'mustHaveTechnologies',
  NiceToHaveTechnologies = 'niceToHaveTechnologies',
  Salary = 'salary',
}

export enum CandidateStatus {
  Hidden = 1,
  Presented = 2,
  Selected = 3,
  Invited = 4,
  Rejected = 5,
  Contacted = 6
}

export enum CandidatePaymentStatus {
  NotPaid = 1,
  InProgress = 2,
  Paid = 3
}

export interface Candidate {
  id: number;
  name: string;
  linkedinLink: string;
  enabled: boolean;
  status: number;
  paymentStatus: number;
  comment: string;
  info: any;
}