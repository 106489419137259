import { defineStore } from 'pinia'
import { User } from '@/interfaces/User'

interface UserStoreState {
  user: User | null;
}
export const useUserStore = defineStore('user', {
  state: (): UserStoreState  => ({
    user: null
  }),
  actions: {
    setUser(user: User) {
      this.user = user
    },
  },
})