import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'

import { AuthRoutes } from '@/router/AuthRoutes'
import { JobRoutes } from '@/router/JobRoutes'
import { PaymentRoutes } from '@/router/PaymentRoutes'

import { StorageKeys } from '@/interfaces/Storage'
import { UN_REGISTERED_USER_ID } from '@/utils/constants'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('@/pages/MainPage.vue'),
    redirect: '/dashboard',
    meta: {
      requiredAuth: true,
      layout: DefaultLayout
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/pages/DashboardPage.vue'),
      },
      {
        path: '/onboarding',
        name: 'OnBoarding',
        component: () => import('@/pages/OnBoardingPage.vue'),
        meta: {
          requiredAuth: false,
          onBoarding: true,
        },
      },
      ...AuthRoutes,
      ...JobRoutes,
      ...PaymentRoutes
    ],
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((
  to, from, next
) => {
  const userId = +localStorage.getItem(StorageKeys.LAT_ID);
  if (to.meta.enoughAnonymAuth && (userId || userId === UN_REGISTERED_USER_ID)) {
    next();
  } else if (to.meta.requiredAuth && (!userId || userId === UN_REGISTERED_USER_ID)) {
    next({ name: 'Login' });
  } else {
    next();
  }

});