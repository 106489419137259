import AuthLayout from '@/layouts/AuthLayout.vue'

export const AuthRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/login/LoginPage.vue'),
    meta: {
      layout: AuthLayout,
      requiredAuth: false,
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/pages/login/RegisterPage.vue'),
    meta: {
      layout: AuthLayout,
      requiredAuth: false,
    }
  }
]