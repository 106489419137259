<template>
  <div class="text-center">
    <v-overlay
      :model-value="true"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script lang="ts">
</script>