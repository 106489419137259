<template>
  <v-dialog
    class="job-comment"
    width="600"
    :model-value="modelValue"
    @update:model-value="updateValue"
  >
    <v-card class="job-comment__card">
      <base-close-button
        class="job-comment__close"
        @click="updateValue(false)"
      />
      <div>
        <p class="job-comment__title">
          What do you think about the candidate?
        </p>
        <p class="job-comment__text">
          What stood out positively? Any gaps or issues? Your insights will help us deliver the best candidates.
        </p>
        <div class="job-comment__textarea">
          <textarea
            v-model="textarea"
            class="job-comment__textarea__item base-text-area"
            placeholder="Enter your thoughts here"
          />
        </div>
        <div class="job-comment__btn flex">
          <p class="job-comment__cancel base-text-button base-text-button_blue" @click="updateValue(false)">
            Cancel
          </p>
          <base-button
            small
            @click="save"
          >
            Save
          </base-button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue'
import BaseCloseButton from '@/components/Core/BaseCloseButton.vue'
import BaseButton from '@/components/Core/BaseButton.vue'
import { useModalsStore } from '@/store/ModalsStore'

defineProps({
  modelValue: {
    type: Boolean,
    required: true,
    default: false
  }
})
const modalsStore = useModalsStore()


const emit = defineEmits([ 'update:modelValue'])
const textarea = ref(modalsStore.jobCandidateCommentModal.data?.comment || '')

const save = async () => {
  if (modalsStore.jobCandidateCommentModal.successCallback) {
    await modalsStore.jobCandidateCommentModal.successCallback(textarea.value)
  }
  updateValue(false)
}

const updateValue = (value: boolean) => {
  emit('update:modelValue', value)
}
</script>
<style lang="scss" scoped>
.job-comment {
  &__card {
    border-radius: 16px!important;
    padding: 40px;
    box-shadow: 0 4px 40px 0 #5E5DE733;
    position: relative;
  }
  &__close {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 1;
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  &__text {
    font-size: 16px;
    color: $DOVE_GRAY;
    margin-bottom: 32px;
  }
  &__textarea {
    margin-bottom: 18px;
    &__item {
      height: 124px;
    }
  }
  &__cancel {
    margin-right: 48px;
  }
  &__btn {
    justify-content: flex-end;
    align-items: center;
  }
}
</style>