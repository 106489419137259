import JobLayout from '@/layouts/JobLayout.vue'
export const JobRoutes = [
  {
    path: '/job/:id?/:edit?',
    name: 'Job',
    component: () => import('@/pages/Job/JobPage.vue'),
    meta: {
      enoughAnonymAuth: true,
      layout: JobLayout
    }
  },
  {
    path: '/job/view/:id',
    name: 'JobView',
    component: () => import('@/pages/Job/JobViewPage.vue'),
    meta: {
      enoughAnonymAuth: true,
      layout: JobLayout
    }
  },
  {
    path: '/job/analyze',
    name: 'JobAnalyze',
    component: () => import('@/pages/Job/JobAnalyzePage.vue'),
    meta: {
      enoughAnonymAuth: true,
      layout: JobLayout
    }
  },
  {
    path: '/:id/search-candidates-google',
    name: 'JobSearchCandidatesGoogle',
    component: () => import('@/pages/Job/JobSearchCandidatesGooglePage.vue'),
    meta: {
      layout: JobLayout
    }
  },
  {
    path: '/:id/search-candidates-google-combined',
    name: 'JobSearchCandidatesGoogleCombined',
    component: () => import('@/pages/Job/JobSearchCandidatesGoogleCombinedPage.vue'),
    meta: {
      layout: JobLayout
    }
  },
  {
    path: '/:id/candidates',
    name: 'YourCandidates',
    redirect: (to) => {
      return { name: 'JobCandidatesReview', params: { id: to.params.id } }
    },
    meta: {
      layout: JobLayout
    }
  },
  // {
  //   path: '/:id/interviews',
  //   name: 'YourInterviews',
  //   component: () => import('@/pages/Job/YourInterviewsPage.vue'),
  //   meta: {
  //     layout: JobLayout
  //   }
  // },
  {
    path: '/:id/review-candidates',
    name: 'JobCandidatesReview',
    component: () => import('@/pages/Job/JobSearchCandidatesGoogleCombinedPage.vue'),
    meta: {
      layout: JobLayout
    }
  },
  {
    path: '/:id/invited-candidates',
    name: 'JobCandidatesInvited',
    component: () => import('@/pages/Job/JobCandidatesInvitedPage.vue'),
    meta: {
      layout: JobLayout
    }
  },
  {
    path: '/:id/rejected-candidates',
    name: 'JobCandidatesRejected',
    component: () => import('@/pages/Job/JobCandidatesRejectedPage.vue'),
    meta: {
      layout: JobLayout
    }
  },
  {
    path: '/:id/calibration',
    name: 'JobCalibration',
    component: () => import('@/pages/Job/JobCalibrationPage.vue'),
    meta: {
      enoughAnonymAuth: true,
      layout: JobLayout
    }
  },
  {
    path: '/:id/first-touch',
    name: 'JobFirstTouch',
    component: () => import('@/pages/Job/JobFirstTouchPage.vue'),
    meta: {
      enoughAnonymAuth: true,
      layout: JobLayout
    }
  },
  {
    path: '/:id/follow-ups',
    name: 'JobFollowUp',
    component: () => import('@/pages/Job/JobFollowUpPage.vue'),
    meta: {
      enoughAnonymAuth: true,
      layout: JobLayout
    }
  },
]