export const PaymentRoutes = [
  {
    path: '/payment-success',
    name: 'PaymentSuccess',
    component: () => import('@/pages/Payment/PaymentSuccessPage.vue'),
  },
  {
    path: '/payment-error',
    name: 'PaymentError',
    component: () => import('@/pages/Payment/PaymentErrorPage.vue'),
  }
]