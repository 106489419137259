<template>
  <div class="side-bar">
    <div class="side-bar__wrap flex">
      <nav class="side-bar__nav">
        <div class="side-bar__logo">
          <img
            v-svg-inline
            src="@/assets/images/icons/sidebar-logo.svg"
            alt="logo"
          >
        </div>
        <slot name="sidebar" />
      </nav>
      <div class="side-bar__content">
        <div class="side-bar__header">
          <slot name="header">
            <div
              v-if="userId !== UN_REGISTERED_USER_ID"
              class="side-bar__logout"
              @click="logout"
            >
              <img
                v-svg-inline
                src="@/assets/images/icons/logout.svg"
                alt="logout"
              >
              <span>
                Logout
              </span>
            </div>
          </slot>
        </div>
        <div class="side-bar__content__wrap">
          <slot name="page">
            <router-view />
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { StorageKeys } from '@/interfaces/Storage'
import { UN_REGISTERED_USER_ID } from '@/utils/constants'

const userId = +localStorage.getItem(StorageKeys.LAT_ID)

const logout = () => {
  localStorage.removeItem(StorageKeys.LAT_ID)
  localStorage.removeItem(StorageKeys.CALENDLY_LINK)
  localStorage.removeItem(StorageKeys.UNREGISTERED_JOB)
  window.location.href = '/'
}
</script>

<style lang="scss" scoped>
.side-bar {
  $this: &;
  &__wrap {
    height: 100vh;
    align-items: stretch;
  }
  &__nav {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 232px;
    padding: 20px 20px 20px 20px;
    flex-shrink: 0;
    box-shadow: 0 5px 15px 0 rgba(94, 93, 231, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
    @-moz-document url-prefix() {
      scrollbar-color: #B8B9CA transparent;
      scrollbar-width: thin;
    }
    &::-webkit-scrollbar {
      width: 6px;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      border-radius: 16px;
    }
    &::-webkit-scrollbar-thumb {
      background: #B8B9CA;
      border-radius: 16px;
      cursor: pointer;
    }
  }
  &__logo {
    margin-bottom: 64px;
    svg, img {
      display: block;
      max-width: 80px;
      margin: 0 auto;
    }
  }
  &__content {
    padding-left: 232px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    &__wrap {
      padding: 32px 24px;
      flex-grow: 1;
      background: $SILVER;
    }
  }
  &__header {
    flex-shrink: 0;
    min-height: 108px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
  }
  &__logout {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    span {
      margin-left: 8px;
      color: $PRIMARY_PURPLE;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>