import { HttpService } from '@/services/core/HttpService'
import { PaginationQueryContract } from '@/interfaces/PaginationQueryContract'

export class JobService extends HttpService {
  async getJobs (userId: number, params: PaginationQueryContract) {
    return await this.axios.get(`/api/lat/jobs/${userId}`, { params })
  }

  async getJob (hash: string) {
    return await this.axios.get(`/api/lat/job/${hash}`)
  }

  async createJob (data: { userId?: number, name: string}) {
    return await this.axios.post('/api/lat/job', data)
  }

  async updateJob (hash: string, data: { description?: string, structured_description?: any, candidates_calibration?: any, user_id?: number }) {
    return await this.axios.put(`/api/lat/job/${hash}`, data)
  }

  async getCandidates (hash: string, params: PaginationQueryContract) {
    return await this.axios.get(`/api/lat/job/${hash}/candidates`, { params })
  }

  async getCandidatesCounts (hash: string) {
    return await this.axios.get(`/api/lat/job/${hash}/candidates-counts-by-status`)
  }

  async createCandidates (data: {
    job_hash: string,
    name: string,
    info: any,
    linkedin: string
  }[]) {
    return await this.axios.post('/api/lat/candidates', data)
  }

  async findCandidates (hash: string) {
    return await this.axios.put(`/api/lat/job/${hash}/order`)
  }

  async changeCandidatesStatus (data: { candidates: any, status_id: number }) {
    return await this.axios.post('/api/lat/candidates-status', data)
  }

  async editComment (id: number, data: { client_comment: string }) {
    return await this.axios.put(`/api/lat/candidate/${id}/comment`, data)
  }
}