<template>
  <div class="auth-layout">
    <div class="auth-layout__wrap flex">
      <div class="auth-layout__left">
        <div class="auth-layout__left__wrap">
          <router-view />
        </div>
      </div>
      <div class="auth-layout__right">
        <div class="auth-layout__right__wrap">
          <div class="auth-layout__testimonials">
            <base-testimonial
              v-for="testimonial in testimonials"
              :key="testimonial.name"
              class="auth-layout__testimonial"
              :name="testimonial.name"
              :position="testimonial.position"
              :img="testimonial.img"
              :text="testimonial.text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import BaseTestimonial from '@/components/Core/BaseTestimonial.vue'
import Vasili from '@/assets/images/testimonials/vasili@2x.png'
import Nicolas from '@/assets/images/testimonials/nicolas@2x.png'

const testimonials = [
  {
    name: 'Vasili Shynkarenka',
    position: 'Founder, AI Study Camp',
    img: Vasili,
    text: 'I tried Sourser to find somebody smart and scrappy to run admin for my new project and was very impressed with the amount and quality of candidates it provided. I basically got a pipeline to pick from without lifting a finger.',
  },
  {
    name: 'Nicolas Toper',
    position: 'Co-Founder, Inboxbooster',
    img: Nicolas,
    text: 'It was impressive seeing Sourser find top-tier candidates and seamlessly arrange interviews. The amount of leg work it saved me is truly remarkable. Big thumbs up to Sourser!',
  }
]
</script>
<style lang="scss" scoped>
.auth-layout {
  &__wrap {
    width: 100%;
    min-height: 100vh;
    align-items: stretch;
    justify-content: space-between;
  }
  &__left {
    width: 49%;
    @include sm {
      width: 100%;
    }
    &__wrap {
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      padding: 80px 40px;
    }
  }
  &__right {
    position: relative;
    z-index: 2;
    width: 51%;
    background: $PRIMARY_PURPLE;
    @include sm {
      display: none;
    }
    &__wrap {
      display: flex;
      position: relative;
      z-index: 2;
      height: 100%;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      padding: 80px 40px;
    }
  }
  &__testimonial {
    max-width: 490px;
    width: 100%;
    margin-bottom: 110px;
    &:nth-child(even) {
      margin-left: auto;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>