import { defineStore } from 'pinia'

export const useModalsStore = defineStore('modals', {
  state: () => ({
    jobCandidateCommentModal: {
      isShown: false,
      successCallback: null,
      cancelCallback: null,
      data: null
    },
    jobMoreCandidatesModal: {
      isShown: false,
      successCallback: null,
      cancelCallback: null,
      data: null
    },
    jobCandidateRejectModal: {
      isShown: false,
      successCallback: null,
      cancelCallback: null,
      data: null
    },
    planPromocodeModal: {
      isShown: false,
      successCallback: null,
      cancelCallback: null,
      data: null
    },
    jobDescriptionEditModal: {
      isShown: false,
      successCallback: null,
      cancelCallback: null,
      data: null
    },
    jobAnswerEditModal: {
      isShown: false,
      successCallback: null,
      cancelCallback: null,
      data: null
    },
    jobAnalyzingWaitingModal: {
      isShown: false,
      successCallback: null,
      cancelCallback: null,
      data: null
    },
    jobCalendlyModal: {
      isShown: false,
      successCallback: null,
      cancelCallback: null,
      data: null
    }
  }),
  actions: {
    showModal(modal: string, { successCallback = null, cancelCallback = null, data = null } = {}) {
      this[modal].isShown = true
      this[modal].successCallback = successCallback
      this[modal].cancelCallback = cancelCallback
      this[modal].data = data
    },
    hideModal(modal) {
      this[modal].isShown = false
      this[modal].successCallback = null
      this[modal].cancelCallback = null
      this[modal].data = null
    }
  },
})