<template>
  <side-bar-template class="job-layout">
    <template #sidebar>
      <ul class="job-layout__list">
        <li
          v-for="item in filteredMenu"
          :key="item.route"
          class="job-layout__list__item"
        >
          <span class="job-layout__link">
            <div
              class="job-layout__link__top"
              :class="{
                'job-layout__link__top_active': route.matched.some(({ path } : { path: string }) => path.includes(item.routeCheck)),
                'job-layout__link__top_active-children': item.subItems.some((subItem) => route.matched.some(({ path } : { path: string }) => path.includes(subItem.routeCheck))),
                'job-layout__link__top_disabled': !item.orderedAt,
                'job-layout__link__top_no-link': !item.route
              }"
              @click.prevent="openPage(item)"
            >
              <img
                v-if="item.icon"
                v-svg-inline
                :src="item.icon"
                class="job-layout__icon"
                :class="{'job-layout__icon_stroke' :item.isIconStroke}"
                alt="item.icon"
              >
              <span
                class="job-layout__link__title"
              >
                {{ item.title }}
              </span>
              <span
                v-if="item.count"
                class="job-layout__subitem__count"
                :class="{
                  'job-layout__subitem__count_blue': item.routeCheck === '/review-candidates'
                }"
              >
                {{ item.count }}
              </span>
            </div>
            <span class="job-layout__list__subitem">
              <div
                v-for="subItem in item.subItems"
                :key="subItem.route"
                class="job-layout__subitem flex"
              >
                <span

                  class="job-layout__link__title"
                  :class="{
                    'job-layout__link__title_active': route.matched.some(({ path } : { path: string }) => path.includes(subItem.routeCheck)),
                    'job-layout__link__title_disabled': !subItem.orderedAt
                  }"
                  @click.prevent.stop="openPage(subItem)"
                >
                  {{ subItem.title }}
                </span>
                <span
                  v-if="subItem.count"
                  class="job-layout__subitem__count"
                  :class="{
                    'job-layout__subitem__count_blue': subItem.routeCheck === '/review-candidates'
                  }"
                >
                  {{ subItem.count }}
                </span>
              </div>
            </span>
          </span>
        </li>
      </ul>
    </template>
    <template #header>
      <div class="job-layout__header">
        <p class="h3">
          {{ jobStore.jobName }}
        </p>
        <div
          v-if="userId !== UN_REGISTERED_USER_ID"
          class="job-layout__logout"
          @click="logout"
        >
          <img
            v-svg-inline
            src="@/assets/images/icons/logout.svg"
            alt="logout"
          >
          <span>
            Logout
          </span>
        </div>
      </div>
    </template>
  </side-bar-template>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import SideBarTemplate from '@/layouts/SideBarTemplate.vue'

import { useJobStore } from '@/store/JobStore'
import { StorageKeys } from '@/interfaces/Storage'
import { useFetchCandidatesCounts } from '@/utils/useFetchCandidatesCounts'

import Document from '@/assets/images/icons/document.svg'
import Candidates from '@/assets/images/icons/candidates.svg'
import ArrowLeftDashboard from '@/assets/images/icons/arrow-left-dashboard.svg'
import Calendar from '@/assets/images/icons/calendar.svg'
import Send from '@/assets/images/icons/send.svg'

import { CandidateStatus } from '@/interfaces/Job'
import { UN_REGISTERED_USER_ID } from '@/utils/constants'

const route = useRoute()
const router = useRouter()
const jobStore = useJobStore()
const userId = +localStorage.getItem(StorageKeys.LAT_ID)

const menu = reactive([
  {
    route: 'Dashboard',
    routeCheck: '/dashboard',
    params: null,
    orderedAt: true,
    title: 'Back to Dashboard',
    icon: ArrowLeftDashboard,
    isIconStroke: true,
    subItems: []
  },
  {
    route: 'JobView',
    routeCheck: '/job',
    params: computed(() => ({ id: jobStore.jobHash })),
    orderedAt: true,
    title: 'Job Description',
    icon: Document,
    isIconStroke: false,
    subItems: []
  },
  {
    route: null,
    routeCheck: null,
    params: computed(() => ({ id: jobStore.jobHash })),
    orderedAt: computed( () => jobStore.orderedAt),
    title: 'Your Candidates',
    icon: Candidates,
    isIconStroke: false,
    subItems: [
      {
        route: 'JobCalibration',
        routeCheck: '/calibration',
        params: computed(() => ({ id: jobStore.jobHash })),
        orderedAt: computed( () => jobStore.orderedAt),
        title: 'Calibration',
        count: computed( () => jobStore.candidatesCounts[CandidateStatus.Presented])
      },
      {
        route: 'JobCandidatesReview',
        routeCheck: '/review-candidates',
        params: computed(() => ({ id: jobStore.jobHash })),
        orderedAt: computed( () => jobStore.orderedAt),
        title: 'For Review',
        count: null
      },
      {
        route: 'JobCandidatesRejected',
        routeCheck: '/rejected-candidates',
        params: computed(() => ({ id: jobStore.jobHash })),
        orderedAt: computed( () => jobStore.orderedAt),
        title: 'Rejected',
        count: computed( () => jobStore.candidatesCounts[CandidateStatus.Rejected])
      }
    ]
  },
  {
    route: null,
    routeCheck: null,
    params: computed(() => ({ id: jobStore.jobHash })),
    orderedAt: computed( () => jobStore.orderedAt),
    title: 'Outreach',
    icon: Send,
    isIconStroke: true,
    subItems: [
      {
        route: 'JobFirstTouch',
        routeCheck: '/first-touch',
        params: computed(() => ({ id: jobStore.jobHash })),
        orderedAt: computed( () => jobStore.orderedAt),
        title: 'First Touch',
        count: computed( () => jobStore.candidatesCounts[CandidateStatus.Selected])
      },
      {
        route: 'JobFollowUp',
        routeCheck: '/follow-ups',
        params: computed(() => ({ id: jobStore.jobHash })),
        orderedAt: computed( () => jobStore.orderedAt),
        title: 'Follow-ups',
        count: computed( () => jobStore.candidatesCounts[CandidateStatus.Contacted])
      }
    ]
  },
  {
    route: 'JobCandidatesInvited',
    routeCheck: '/invited-candidates',
    params: computed(() => ({ id: jobStore.jobHash })),
    orderedAt: computed( () => jobStore.orderedAt),
    title: 'Invited',
    count: computed( () => jobStore.candidatesCounts[CandidateStatus.Invited]),
    isIconStroke: false,
    icon: Calendar,
    subItems: []
  }
])

const filteredMenu = computed(() => {
  return menu.map(item => {
    if (item.subItems && item.subItems.length) {
      const filteredSubItems = item.subItems.filter(subItem => {
        return !(userId !== UN_REGISTERED_USER_ID && subItem.routeCheck === '/calibration')
      });

      return { ...item, subItems: filteredSubItems }
    }
    return item
  })
})

const openPage = (item) => {
  if (item.orderedAt) {
    router.push({ name: item.route, params: item.params })
  }
}

const logout = () => {
  localStorage.removeItem(StorageKeys.LAT_ID)
  localStorage.removeItem(StorageKeys.CALENDLY_LINK)
  window.location.href = '/'
}

const getCandidatesCounts = async () => {
  jobStore.setCandidatesCounts({})
  if(route.params.id) {
    await useFetchCandidatesCounts(route.params.id)
  }
}

getCandidatesCounts()
</script>

<style lang="scss" scoped>
.job-layout {
  $this: &;
  &__list {
    &__item {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__subitem {
      display: block;
      padding-left: 32px;
    }
  }
  &__link {
    display: block;
    &__top {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      position: relative;
      padding: 10px 0;
      img, svg {
        display: block;
        width: 24px;
        height: auto;
        margin-right: 8px;
      }
      &:deep(path) {
        fill: $GRAPHITE;
      }
      .job-layout__icon_stroke {
        &:deep(path) {
          fill: transparent;
          stroke: $GRAPHITE;
        }
      }
      &:hover, &_active, &_active-children {
        &:deep(path) {
          fill: $PRIMARY_PURPLE;
        }
        .job-layout__icon_stroke {
          &:deep(path) {
            fill: transparent;
            stroke: $PRIMARY_PURPLE;
          }
        }
        .job-layout__link__title {
          color: $PRIMARY_PURPLE;
        }
      }
      &_no-link {
        pointer-events: none;
      }
      &_active {
        &::after {
          content: '';
          position: absolute;
          display: block;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 232px;
          height: 100%;
          z-index: -1;
          background: #F7F7FE;
        }
      }
      .job-layout__link__title {
        padding: 0;
      }
    }
    &__title {
      display: block;
      font-size: 16px;
      font-weight: 500;
      color: $GRAPHITE;
      cursor: pointer;
      position: relative;
      padding: 10px 0;
      &:not(.job-layout__link__title_disabled):hover {
        color: $PRIMARY_PURPLE;
      }
      &_active {
        color: $PRIMARY_PURPLE;
        &::after {
          content: '';
          position: absolute;
          display: block;
          left: -52px;
          top: 50%;
          transform: translateY(-50%);
          width: 232px;
          height: 100%;
          z-index: -1;
          background: #F7F7FE;
        }
      }
      &_disabled {
        cursor: not-allowed;
      }
    }
  }
  &__subitem {
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 12px;
    &:first-child {
      margin-top: 12px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &__count {
      font-size: 14px;
      font-weight: 500;
      color: $PRIMARY_PURPLE;
      padding: 1.5px 8px;
      margin-left: auto;
      border-radius: 7px;
      background: transparent;
      cursor: default;
      &_blue {
        background: $PRIMARY_PURPLE;
        color: $WHITE;
      }
    }
  }
  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      font-size: 20px;
      font-weight: 600;
      color: $GRAPHITE;
      &:hover {
        color: $PRIMARY_PURPLE;
      }
    }
  }
  &__logout {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    span {
      margin-left: 8px;
      color: $PRIMARY_PURPLE;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>