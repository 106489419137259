<template>
  <div class="app">
    <component :is="layout" />
    <job-comment-modal v-if="modalsStore.jobCandidateCommentModal.isShown" v-model="modalsStore.jobCandidateCommentModal.isShown" />
    <job-more-candidates-modal v-if="modalsStore.jobMoreCandidatesModal.isShown" v-model="modalsStore.jobMoreCandidatesModal.isShown" />
    <plan-promocode-modal v-if="modalsStore.planPromocodeModal.isShown" v-model="modalsStore.planPromocodeModal.isShown" />
    <job-candidate-reject-modal v-if="modalsStore.jobCandidateRejectModal.isShown" v-model="modalsStore.jobCandidateRejectModal.isShown" />
    <job-description-edit-modal v-if="modalsStore.jobDescriptionEditModal.isShown" v-model="modalsStore.jobDescriptionEditModal.isShown" />
    <job-answer-edit-modal v-if="modalsStore.jobAnswerEditModal.isShown" v-model="modalsStore.jobAnswerEditModal.isShown" />
    <job-analyzing-waiting-modal v-if="modalsStore.jobAnalyzingWaitingModal.isShown" v-model="modalsStore.jobAnalyzingWaitingModal.isShown" />
    <job-calendly-modal v-if="modalsStore.jobCalendlyModal.isShown" v-model="modalsStore.jobCalendlyModal.isShown" />
    <base-loader v-if="loaderStore.loading" class="app-loader" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import JobCommentModal from '@/components/Modals/JobCommentModal.vue'
import JobMoreCandidatesModal from '@/components/Modals/JobMoreCandidatesModal.vue'
import PlanPromocodeModal from '@/components/Modals/PlanPromocodeModal.vue'
import JobCandidateRejectModal from '@/components/Modals/JobCandidateRejectModal.vue'
import JobDescriptionEditModal from '@/components/Modals/JobDescriptionEditModal.vue'
import JobAnswerEditModal from '@/components/Modals/JobAnswerEditModal.vue'
import JobAnalyzingWaitingModal from '@/components/Modals/JobAnalyzingWaitingModal.vue'
import JobCalendlyModal from '@/components/Modals/JobCalendlyModal.vue'
import BaseLoader from '@/components/Core/BaseLoader.vue'

import { useModalsStore } from '@/store/ModalsStore'
import { useLoaderStore } from '@/store/LoaderStore'

const modalsStore = useModalsStore()
const loaderStore = useLoaderStore()

const route = useRoute()

const layout = computed(() => {
  return route.meta.layout || EmptyLayout
});
</script>

<style lang="scss" scoped>
.app {
  position: relative;
  z-index: 1;
}
.app-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 110;
}
</style>
