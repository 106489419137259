import { defineStore } from 'pinia'
import { UserService } from '@/services/UserService'
import { useUserStore } from '@/store/UserStore'
import { StorageKeys } from '@/interfaces/Storage'
import { UN_REGISTERED_USER_ID } from '@/utils/constants'

const userService = new UserService()

export const useMainStore = defineStore('main', {
  actions: {
    async initApp() {
      const userStore = useUserStore()
      if (localStorage.getItem(StorageKeys.LAT_ID) && +localStorage.getItem(StorageKeys.LAT_ID) !== UN_REGISTERED_USER_ID) {
        const res = await userService.getUser(localStorage.getItem(StorageKeys.LAT_ID))
        userStore.setUser(res.data.data)
      }
    }
  },
})