import { HttpService } from '@/services/core/HttpService'
interface UserAuth {
  email: string;
  password: string;
}

interface CalendlyLink {
  calendly_link: string;
}

export class UserService extends HttpService {
  async login (data: UserAuth) {
    return await this.axios.post('/api/lat/user/auth', data)
  }

  async register (data: UserAuth) {
    return await this.axios.post('/api/lat/user/create', data)
  }

  async getUser (id: number) {
    return await this.axios.get(`/api/lat/user/${id}`)
  }

  async activatePromocode ({ id, promocode }: { id: number; promocode: string }) {
    return await this.axios.post(`/api/lat/subscription-promocode/${id}/${promocode}`)
  }

  async setCalendlyLink (id: number, data: CalendlyLink) {
    return await this.axios.post(`/api/lat/profile/${id}/calendly-link`, data)
  }
}