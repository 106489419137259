<template>
  <div
    class="base-input"
    :class="{
      'base-input__front-icon': $slots['front-icon'],
      'base-input__front-icon_active': $slots['front-icon'] && modelValue
    }"
  >
    <p v-if="label" class="base-input__label">
      {{ label }}
    </p>
    <div class="base-input__wrap">
      <input
        :type="type"
        :min="min"
        :max="max"
        :value="modelValue"
        :placeholder="placeholder"
        @input="updateModelValue"
      >
      <div v-if="$slots['front-icon']" class="base-input__icon">
        <slot name="front-icon" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  min: {
    type: Number,
    default: -Infinity
  },
  max: {
    type: Number,
    default: Infinity
  }
})

const updateModelValue = (event: Event) => {
  let newValue: string | number = (event.target as HTMLInputElement).value
  if(props.type === 'number') {
    let numericValue = parseFloat((event.target as HTMLInputElement).value)
    if (isNaN(numericValue) || numericValue < props.min) {
      newValue = props.min.toString()
    }

    if (numericValue > props.max) {
      newValue = props.max.toString()
    }
    (event.target as HTMLInputElement).value = newValue
  }
  emit('update:modelValue', newValue)
}
</script>
<style lang="scss" scoped>
.base-input {
  &__label {
    font-size: 16px;
    color: $DOVE_GRAY;
    font-weight: 500;
    margin-bottom: 8px;
  }
  input {
    display: block;
    max-width: 100%;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 0 16px;
    background: $SILVER;
    height: 48px;
    line-height: 48px;
    color: $GRAPHITE;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    transition: all 0.2s ease-in-out;
    &::-webkit-input-placeholder { /* Edge */
      color: $NEUTRAL_200;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $NEUTRAL_200;
    }
    &::placeholder {
      color: $NEUTRAL_200;
    }
    &:focus {
      border-color: $PRIMARY_PURPLE;
    }
  }
  &__wrap {
    position: relative;
  }
  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    pointer-events: none;
    width: 20px;
    height: 20px;
    &:deep(svg, img) {
      display: block;
      max-width: 100%;
      height: auto;
      path {
        stroke: $NEUTRAL_200;
      }
    }
  }
  &__front-icon {
    input {
      padding-left: 52px;
    }
    &_active {
      .base-input__icon {
        &:deep(svg, img) {
          path {
            stroke: $GRAPHITE;
          }
        }
      }
    }
  }
}
</style>