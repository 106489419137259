<template>
  <v-dialog
    class="job-answer-edit"
    width="768"
    :model-value="modelValue"
    @update:model-value="updateValue"
  >
    <v-card class="job-answer-edit__card">
      <base-close-button
        class="job-answer-edit__close"
        @click="updateValue(false)"
      />
      <div class="job-answer-edit__card__wrap">
        <h3 class="job-answer-edit__title h1">
          Edit Answer
        </h3>
        <p class="job-answer-edit__question">
          {{ title }}
        </p>
        <div class="job-answer-edit__textarea">
          <textarea
            v-model="textarea"
            class="job-answer-edit__textarea__item base-text-area"
            placeholder="Type or paste your answere here"
          />
        </div>
        <div class="job-answer-edit__btn flex">
          <p class="job-answer-edit__cancel base-text-button base-text-button_blue" @click="updateValue(false)">
            Cancel
          </p>
          <base-button
            small
            @click="save"
          >
            Save
          </base-button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue'
import BaseCloseButton from '@/components/Core/BaseCloseButton.vue'
import BaseButton from '@/components/Core/BaseButton.vue'
import { useModalsStore } from '@/store/ModalsStore'

defineProps({
  modelValue: {
    type: Boolean,
    required: true,
    default: false
  }
})
const modalsStore = useModalsStore()


const emit = defineEmits([ 'update:modelValue'])
const textarea = ref(modalsStore.jobAnswerEditModal.data?.answer || '')
const title = ref(modalsStore.jobAnswerEditModal.data?.title || '')

const save = async () => {
  if (modalsStore.jobAnswerEditModal.successCallback) {
    await modalsStore.jobAnswerEditModal.successCallback(textarea.value)
  }
  updateValue(false)
}

const updateValue = (value: boolean) => {
  emit('update:modelValue', value)
}
</script>
<style lang="scss" scoped>
.job-answer-edit {
  &__card {
    border-radius: 16px!important;
    padding: 40px;
    box-shadow: 0 4px 40px 0 #5E5DE733;
    position: relative;
  }
  &__close {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 1;
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
  }
  &__question {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  &__textarea {
    margin-bottom: 18px;
    &__item {
      height: 224px;
    }
  }
  &__cancel {
    margin-right: 48px;
  }
  &__btn {
    justify-content: flex-end;
    align-items: center;
  }
}
</style>