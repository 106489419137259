import { createApp } from 'vue'
import { router } from '@/router/Router'
import Toast, { PluginOptions, POSITION } from 'vue-toastification'
import VueSvgInlinePlugin from 'vue-svg-inline-plugin'
import * as amplitude from '@amplitude/analytics-browser';

import 'vue-toastification/dist/index.css'
import '@/styles/settings.scss'
import 'vuetify/dist/vuetify.css'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import { ExtraErrorData } from '@sentry/integrations'
import App from './App.vue'

import { useMainStore } from '@/store/MainStore'

const vuetify= createVuetify()
const pinia = createPinia()
const toastOptions: PluginOptions = { position: POSITION.TOP_RIGHT };

const loadingDataPromises = [];
loadingDataPromises.push(useMainStore(pinia).initApp())

amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
  defaultTracking: true
});

Promise.all(loadingDataPromises).finally(() => {
  const app = createApp(App)

  Sentry.init({
    app,
    environment: import.meta.env.MODE,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.Replay(),
      new ExtraErrorData({
        depth: 20,
      })
    ],
    trackComponents: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });

  app
    .use(router)
    .use(pinia)
    .use(vuetify)
    .use(Toast, toastOptions)
    .use(VueSvgInlinePlugin)
    .mount('#app')
})


