<template>
  <v-dialog
    class="job-answer-edit"
    width="600"
    :model-value="modelValue"
    @update:model-value="updateValue"
  >
    <v-card class="job-answer-edit__card">
      <base-close-button
        class="job-answer-edit__close"
        @click="updateValue(false)"
      />
      <div class="job-answer-edit__card__wrap">
        <h3 class="job-answer-edit__title h1">
          {{ title }}
        </h3>
        <p class="job-answer-edit__question">
          {{ description }}
        </p>
        <div class="job-answer-edit__input">
          <base-input
            v-model="calendlyLink"
            class="job-answer-edit__input__item"
            placeholder="Paste your link here"
          >
            <template #front-icon>
              <img
                v-svg-inline
                src="@/assets/images/icons/calendar.svg"
                alt="calendar"
              >
            </template>
          </base-input>
        </div>
        <div class="job-answer-edit__btn flex">
          <p class="job-answer-edit__cancel base-text-button base-text-button_blue" @click="updateValue(false)">
            Cancel
          </p>
          <base-button
            small
            :disabled="!calendlyLink"
            @click="save"
          >
            {{ buttonSaveText }}
          </base-button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue'
import BaseCloseButton from '@/components/Core/BaseCloseButton.vue'
import BaseButton from '@/components/Core/BaseButton.vue'
import BaseInput from '@/components/Core/BaseInput.vue'
import { useModalsStore } from '@/store/ModalsStore'

defineProps({
  modelValue: {
    type: Boolean,
    required: true,
    default: false
  }
})
const modalsStore = useModalsStore()


const emit = defineEmits([ 'update:modelValue'])
const calendlyLink = ref(modalsStore.jobCalendlyModal.data?.calendlyLink || '')
const title = ref(modalsStore.jobCalendlyModal.data?.title || '')
const description = ref(modalsStore.jobCalendlyModal.data?.description || '')
const buttonSaveText = ref(modalsStore.jobCalendlyModal.data?.buttonSaveText || '')

const save = async () => {
  if (modalsStore.jobCalendlyModal.successCallback) {
    await modalsStore.jobCalendlyModal.successCallback(calendlyLink.value)
  }
  updateValue(false)
}

const updateValue = (value: boolean) => {
  emit('update:modelValue', value)
}
</script>
<style lang="scss" scoped>
.job-answer-edit {
  &__card {
    border-radius: 16px!important;
    padding: 40px;
    box-shadow: 0 4px 40px 0 #5E5DE733;
    position: relative;
  }
  &__close {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 1;
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  &__question {
    font-size: 16px;
    font-weight: 400;
    color: $DOVE_GRAY;
    margin-bottom: 16px;
  }
  &__input {
    margin-bottom: 18px;
  }
  &__cancel {
    margin-right: 48px;
  }
  &__btn {
    justify-content: flex-end;
    align-items: center;
  }
}
</style>