<template>
  <div class="base-close-button">
    <div class="base-close-button__line" />
    <div class="base-close-button__line" />
  </div>
</template>
<script setup lang="ts">
</script>
<style lang="scss" scoped>
.base-close-button {
  $this: &;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: $PLATINUM;
  cursor: pointer;
  &__line {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 12px;
    height: 2px;
    background: $CHARCOAL;
    border-radius: 10px;
    transition: all 0.25s ease-in-out;
    &:first-child {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:last-child {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  &:hover {
    #{$this}__line {
      background: $PRIMARY_PURPLE;
      &:first-child {
        transform: translate(-50%, -50%) rotate(45deg) scale(1.5);
      }
      &:last-child {
        transform: translate(-50%, -50%) rotate(-45deg) scale(1.5);
      }
    }
  }
}
</style>