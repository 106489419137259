<template>
  <button
    class="base-button"
    :class="{
      'base-button_disabled': disabled,
      'base-button_small': small
    }"
    @click="onClick"
  >
    <slot />
  </button>
</template>
<script setup lang="ts">
import { defineProps } from 'vue'

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click']);

const onClick = ($event) => {
  emit('click', $event)
}
</script>
<style lang="scss" scoped>
.base-button {
  border: none;
  outline: none;
  cursor: pointer;
  background: $PRIMARY_PURPLE;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: $WHITE;
  height: 56px;
  line-height: 1;
  padding: 0 23px;
  transition: all 0.2s ease-in-out;
  &_disabled {
    background: $PRIMARY_DISABLED;
    pointer-events: none;
  }
  &_small {
    height: 48px;
  }
  &:hover {
    background: $SLATE_BLUE;
  }
}
</style>