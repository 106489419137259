<template>
  <v-dialog
    persistent
    width="561"
    class="analyzing-waiting__modal"
    :model-value="modelValue"
    @update:model-value="updateValue"
  >
    <v-card class="analyzing-waiting__modal__card">
      <div class="analyzing-waiting__modal__img">
        <div class="analyzing-waiting__modal__img__wrap">
          <img
            class="analyzing-waiting__modal__documents"
            src="@/assets/images/icons/documents-without-loop.svg"
            alt="documents"
          >
          <div class="analyzing-waiting__modal__circle">
            <img
              class="analyzing-waiting__modal__search"
              src="@/assets/images/icons/search.svg"
              alt="search"
            >
          </div>
        </div>
      </div>
      <h3 class="h1">
        Analyzing your job description
      </h3>
      <p>
        Hang tight! We're just making sure we've got all the details right to find your ideal candidate. Should be just a few moments!
      </p>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'

defineProps({
  modelValue: {
    type: Boolean,
    required: true,
    default: false
  }
})
const emit = defineEmits([ 'update:modelValue'])
const updateValue = (value: boolean) => {
  emit('update:modelValue', value)
}
</script>
<style lang="scss" scoped>
.analyzing-waiting {
  &__modal {
    text-align: center;
    &__card {
      border-radius: 16px!important;
      padding: 64px 40px;
      box-shadow: 0 4px 40px 0 #5E5DE733;
      position: relative;
      h3 {
        margin-bottom: 8px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: $DOVE_GRAY;
        line-height: 1.6;
      }
    }
    &__img {
      text-align: center;
      &__wrap {
        position: relative;
        display: inline-block;
      }
    }
    &__documents {
      width: 202px;
    }
    &__circle {
      position: absolute;
      z-index: 2;
      width: 40px;
      height: 50px;
      left: 50%;
      top: 44%;
      animation: circle-motion 4s linear infinite;
    }
    &__search {
      width: 52px;
      height: auto;
      animation: counter-rotate 4s linear infinite;
    }
  }
}
@keyframes circle-motion {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes counter-rotate {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) rotate(-360deg);
  }
}
</style>
