import { JobService } from '@/services/JobService'
import { useJobStore } from '@/store/JobStore'

const jobService = new JobService()

export const useFetchCandidatesCounts = async (hash: string) => {
  const jobStore = useJobStore()
  const res = await jobService.getCandidatesCounts(hash)
  const obj: {
    [key: number]: number
  } = {}
  res.data.data.forEach((item: { statusId: number; count: string }) => {
    obj[item.statusId] = +item.count
  })
  jobStore.setCandidatesCounts(obj)
  return obj
}
