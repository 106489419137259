<template>
  <v-dialog
    class="job-more-candidates"
    width="728"
    :model-value="modelValue"
    @update:model-value="updateValue"
  >
    <v-card class="job-more-candidates__card">
      <div>
        <p class="job-more-candidates__title">
          Want more candidates?
        </p>
        <p class="job-more-candidates__text">
          No problem! We'll source as many as you'd like for just $5 per additional candidate profile.
        </p>
        <p class="job-more-candidates__text">
          How many additional candidates do you want?
        </p>
        <form
          class="job-more-candidates__form"
        >
          <base-input
            v-model="count"
            :min="1"
            class="job-more-candidates__input"
            type="number"
          />
          <div class="job-more-candidates__btn">
            <v-btn
              color="primary"
              @click="getMoreCandidates"
            >
              Get {{ count }} Candidates
            </v-btn>
          </div>
        </form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue'
import BaseInput from '@/components/Core/BaseInput.vue'

import { useModalsStore } from '@/store/ModalsStore'

defineProps({
  modelValue: {
    type: Boolean,
    required: true,
    default: false
  }
})
const modalsStore = useModalsStore()

const emit = defineEmits([ 'update:modelValue'])
const count = ref(10)

const getMoreCandidates = async () => {
  if (modalsStore.jobMoreCandidatesModal.successCallback) {
    await modalsStore.jobMoreCandidatesModal.successCallback(count.value)
  }
  updateValue(false)
}

const updateValue = (value: boolean) => {
  emit('update:modelValue', value)
}
</script>
<style lang="scss" scoped>
.job-more-candidates {
  &__card {
    padding: 32px;
  }
  &__title {
    font-size: 22px;
    font-weight: 400;
    color: $BLACK;
    margin-bottom: 6px;
  }
  &__text {
    font-size: 16px;
    font-weight: 400;
    color: $BLACK;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__form {
    margin-top: 32px;
  }
  &__input {
    max-width: 200px;
    width: 100%;
  }
  &__btn {
    margin-top: 24px;
    text-align: right;
  }
}
</style>